import { Component, OnInit } from '@angular/core';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

  constructor(
    private bsLocaleService: BsLocaleService,
  ) { }

  public ngOnInit(): void {
    this.bsLocaleService.use('pt-br');
  }

}
