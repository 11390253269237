export const environment = {
  production: true,
  api: 'https://api.benquer.com.br/api/',
  app: 'https://app.benquer.com.br/',
  rollbarConfig: {
    accessToken: 'aefa308dfb5d472ebbbf81cc9b7182ae',
    captureUncaught: true,
    captureUnhandledRejections: true,
    autoInstrument: true,
    payload: {
      environment: 'production'
    }
  }
};
