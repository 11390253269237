import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IApiResponse } from '../interfaces/api-response';
import { ICity } from '../interfaces/city.interface';

@Injectable({ providedIn: 'root' })
export class CitiesService {

  constructor(
    private httpClient: HttpClient
  ) { }

  public getByState(stateId: number): Observable<Array<ICity>> {
    return this.httpClient
      .get<IApiResponse<Array<ICity>>>(`${environment.api}cities/${stateId}`)
      .pipe(map(({ data }) => data));
  }

}
