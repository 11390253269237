import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { ICEP } from '../interfaces/cep.interface';

@Injectable({ providedIn: 'root' })
export class CEPService {

  private readonly api = 'https://brasilapi.com.br/api/cep/v1/';

  constructor(
    private httpClient: HttpClient
  ) { }

  public get(cep: string): Observable<ICEP> {
    return this.httpClient.get<ICEP>(`${this.api}${cep}`);
  }

}
