import { AbstractControl, ValidationErrors } from '@angular/forms';
import * as cpf from '@fnando/cpf';

export function ValidateCPF({ value }: AbstractControl): ValidationErrors | null {

  if (value?.length < 11 || !cpf.isValid(value)) {
    return { cpf: false };
  }

  return null;

}
