export enum InputError {
  default = 'defaultmsg',
  email = 'email',
  minLength = 'minlength',
  maxLength = 'maxlength',
  min = 'min',
  max = 'max',
  required = 'required',
  date = 'date',
  pattern = 'pattern',
  number = 'number',
  url = 'url',
  mask = 'mask',
  cpf = 'cpf',
  cnpj = 'cnpj'
}
