<form [formGroup]="form">

  <div class="row">

    <div class="col-6 form-group mb-3">

      <label for="cep" class="form-label">CEP</label>
      <input type="text" class="form-control w-100" id="cep" name="cep" placeholder="00000-000" mask="00000-000" formControlName="cep" (blur)="onCEPBlur()">
      <app-input-feedback [control]="form.controls.cep"></app-input-feedback>

    </div>

    <div class="form-group mb-3 col-6">

      <label for="state_id" class="form-label">Estado</label>

      <ng-select
        class="form-control-custom"
        formControlName="state_id"
        labelForId="state_id"
        bindLabel="name"
        bindValue="id"
        placeholder="Selecione o estado"
        [clearable]="true"
        [items]="states"
        (change)="onStateChange($event)">
      </ng-select>
      <app-input-feedback [control]="form.controls.state_id"></app-input-feedback>

    </div>

    <div class="form-group mb-3 col-6">

      <label for="city_id" class="form-label">Cidade</label>

      <ng-select
        class="form-control-custom"
        formControlName="city_id"
        labelForId="city_id"
        bindLabel="name"
        bindValue="id"
        placeholder="Selecione a cidade"
        [clearable]="true"
        [loading]="loadingCities"
        [items]="cities">
      </ng-select>
      <app-input-feedback [control]="form.controls.city_id"></app-input-feedback>

    </div>

    <div class="col-6 form-group mb-3">

      <label for="neighborhood" class="form-label">Bairro</label>
      <input type="text" class="form-control w-100" id="neighborhood" name="neighborhood" placeholder="Nome do bairro" formControlName="neighborhood" maxlength="100">
      <app-input-feedback [control]="form.controls.neighborhood"></app-input-feedback>

    </div>

    <div class="col-8 form-group mb-3">

      <label for="address" class="form-label">Endereço</label>
      <input type="text" class="form-control w-100" id="address" name="address" placeholder="Nome da rua, avenida..." formControlName="address" maxlength="100">
      <app-input-feedback [control]="form.controls.address"></app-input-feedback>

    </div>

    <div class="col-4 form-group mb-3">

      <label for="number" class="form-label">Número</label>
      <input #number type="text" class="form-control w-100" id="number" name="number" placeholder="00000" formControlName="number" mask="0*0000">
      <app-input-feedback [control]="form.controls.number"></app-input-feedback>

    </div>

    <div class="col-12 form-group mb-3">

      <label for="complement" class="form-label">Complemento</label>
      <input type="text" class="form-control w-100" id="complement" name="complement" placeholder="Ex.: apartamento, ponto de referência" formControlName="complement" maxlength="100">

    </div>

  </div>

</form>
