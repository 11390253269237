import { ErrorHandler, Inject, Injectable, InjectionToken } from '@angular/core';
import { environment } from '@env';
import * as Rollbar from 'rollbar';

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {

  constructor(
    @Inject(RollbarService) private rollbar: Rollbar
  ) { }

  public handleError(err: any): void {

    console.error(err);

    if (environment.production) {
      this.rollbar.error(err.originalError || err);
    }

  }

}
