import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { InputError } from '../enums/input-error.enum';

@Injectable({ providedIn: 'root' })
export class InputFeedbackService {

  private readonly messages: { [index: string]: string } = {
    defaultMsg: 'Adicione mensagem de erro para {0}. ',
    email: 'Digite um endereço de e-mail válido. ',
    minlength: 'Digite pelo menos {0}  caracteres. ',
    maxlength: 'Você digitou mais do que o máximo de {0} caracteres. ',
    min: 'Digite um número maior que {0}. ',
    max: 'Digite um número menor que {0}. ',
    required: 'Este campo é obrigatório. ',
    date: 'Digite uma data válida. ',
    pattern: 'Certifique-se que a informação digitada segue o padrão {0}. ',
    number: 'Digite um número válido. ',
    url: 'Digite uma URL válida no formato https://www.benquer.com.br/ ',
    mask: 'Formato inválido. ',
    cnpj: 'Informe um CNPJ válido. ',
    cpf: 'Informe um CPF válido. '
  };

  public getMessage(control: AbstractControl, error: string = InputError.default): string {

    const errors = control.errors;

    if (error === InputError.min) {
      return this.messages[error].replace('{0}', errors?.min.min);
    }

    if (error === InputError.max) {
      return this.messages[error].replace('{0}', errors?.max.max);
    }

    if (error === InputError.minLength) {
      return this.messages[error].replace('{0} ', errors?.minlength.requiredLength);
    }

    if (error === InputError.maxLength) {
      return this.messages[error].replace('{0}', errors?.maxlength.requiredLength);
    }

    return this.messages[error];

  }

}
