import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ValidateCPF } from '@core/validators/custom.validator';
import { environment } from '@env';
import { ToastrService } from 'ngx-toastr';
import { catchError } from 'rxjs/operators';
import { PersonType } from 'src/app/core/enums/person-type.enum';
import { RegisterForm } from 'src/app/core/models/register-form.model';
import { RegisterService } from 'src/app/core/services/register.service';
import { AddressFormComponent } from '../address-form/address-form.component';

@Component({
  selector: 'app-register-form',
  templateUrl: './register-form.component.html',
  styleUrls: ['./register-form.component.scss']
})
export class RegisterFormComponent implements OnInit {

  @ViewChild(AddressFormComponent)
  public addressForm!: AddressFormComponent;

  public today = new Date();
  public initialDate = new Date(this.today.setFullYear(this.today.getFullYear() - 18));
  public showPassword = false;
  public showConfirmPassword = false;

  public form = new FormGroup({
    coupon: new FormControl('', []),
    type: new FormControl(PersonType.naturalPerson, [Validators.required]),
    active: new FormControl(true, [Validators.required]),
    name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required]),
    phone: new FormControl('', []),
    password: new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(64)]),
    password_confirmation: new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(64)]),
    cpf_cnpj: new FormControl('', [Validators.required, ValidateCPF]),
    date: new FormControl('', [Validators.required]),
    agree_terms: new FormControl(false, Validators.requiredTrue),
    agree_receive_phone_messages: new FormControl(false),
    agree_receive_mail_messages: new FormControl(false),
  });

  public datePickerConfig = { showWeekNumbers: false };
  public passwordStrength = 0;
  public submitted = false;
  public loading = false;

  public readonly phoneMask = '(00) 0000-00009';
  public readonly mobilePhoneMask = '(00) 0 0000-0000';

  constructor(
    private toastrService: ToastrService,
    private activatedRoute: ActivatedRoute,
    private registerService: RegisterService,
  ) { }

  private checkAdditionalValidations(): void {

    if (this.hasPhoneNumber) {

      this.form.get('agree_receive_phone_messages')?.setValidators(Validators.requiredTrue);
      this.form.get('agree_receive_phone_messages')?.updateValueAndValidity();

    } else {

      this.form.get('agree_receive_phone_messages')?.setValidators(null);
      this.form.get('agree_receive_phone_messages')?.updateValueAndValidity();

    }

    if (this.addressForm?.hasAddress) {

      this.form.get('agree_receive_mail_messages')?.setValidators(Validators.requiredTrue);
      this.form.get('agree_receive_mail_messages')?.updateValueAndValidity();

    } else {

      this.form.get('agree_receive_mail_messages')?.setValidators(null);
      this.form.get('agree_receive_mail_messages')?.updateValueAndValidity();

    }

    this.form.updateValueAndValidity();

  }

  public get isValidName(): boolean {

    const name = this.form.get('name')?.value || '';

    return name.split(' ').length > 1;

  }

  public get password(): string {
    return this.form.get('password')?.value || '';
  }

  public get phoneNumber(): string {
    return this.form.get('phone')?.value || '';
  }

  public get coupon(): string {
    return this.form.get('coupon')?.value || '';
  }

  public get hasPhoneNumber(): boolean {
    return this.phoneNumber !== '' && Boolean(this.form.get('phone')?.valid);
  }

  public get invalidPassword(): boolean {
    return this.passwordStrength < 2;
  }

  public get acceptTermsIsInvalid(): boolean {
    return Boolean(this.form.get('agree_terms')?.touched && this.form.get('agree_terms')?.invalid);
  }

  public get agreeReceivePhoneMessagesIsInvalid(): boolean {
    return Boolean(this.form.get('agree_receive_phone_messages')?.touched && this.form.get('agree_receive_phone_messages')?.invalid);
  }

  public get agreeReceiveMailMessagesIsInvalid(): boolean {
    return Boolean(this.form.get('agree_receive_mail_messages')?.touched && this.form.get('agree_receive_mail_messages')?.invalid);
  }

  public onPasswordStrengthChanged(strength: number): void {
    this.passwordStrength = strength;
  }

  public validateFormFields(formGroup: FormGroup): void {

    if (!formGroup) {
      return;
    }

    if (formGroup.invalid) {
      this.toastrService.error('Preencha todos os campos corretamente');
    }

    Object.keys(formGroup.controls).forEach((field) => {

      const control = formGroup?.get(field);

      if (control instanceof FormControl) {

        control.markAsTouched({ onlySelf: true });
        control.markAsDirty({ onlySelf: true });

      } else if (control instanceof FormGroup) {
        this.validateFormFields(control);
      }

    });

  }

  public togglePasswordVisibility(field: string): void {

    if (field === 'password') {
      this.showPassword = !this.showPassword;
    }

    if (field === 'password_confirmation') {
      this.showConfirmPassword = !this.showConfirmPassword;
    }

  }

  public onSubmit(): void {

    this.submitted = true;

    const payload = new RegisterForm({
      ...this.form.value,
      ...this.addressForm.form.value,
    });

    console.log(payload);

    this.checkAdditionalValidations();
    this.validateFormFields(this.form);

    if (!this.form.valid) {
      return;
    }

    if (this.invalidPassword) {

      this.toastrService.warning('Use no mínimo 8 caracteres, letras maiúsculas, minúsculas, números e símbolos', 'Senha fraca');

      return;

    }

    this.loading = true;

    this.registerService.create(payload)
      .pipe(
        catchError(({ error }) => {

          this.loading = false;
          this.submitted = false;

          if (error.errors) {

            Object.keys(error.errors).forEach((err) => {
              this.toastrService.error(error.errors[err]);
            });

          }

          return error;

        })
      )
      .subscribe(() => {

        this.loading = false;

        this.form.reset();
        this.addressForm.form.reset();

        this.toastrService.success('Cadastro realizado com sucesso. Você será para a o sistema em 5 segundos.');

        setTimeout(() => {
          window.location.href = environment.app;
        }, 5000);

      });

  }

  public ngOnInit(): void {

    this.activatedRoute.queryParamMap.subscribe((params) => {

      const coupon = params.get('coupon');

      if (coupon) {
        this.form.patchValue({ coupon: params.get('coupon') });
      }

    });

  }

}
