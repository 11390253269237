import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { IState } from '../interfaces/state.interface';

@Injectable({ providedIn: 'root' })
export class StatesService {

  public getAll(): Observable<Array<IState>> {
    return of([{
      id: 11,
      name: 'Rondônia',
      abbr: 'RO'
    }, {
      id: 12,
      name: 'Acre',
      abbr: 'AC'
    }, {
      id: 13,
      name: 'Amazonas',
      abbr: 'AM'
    }, {
      id: 14,
      name: 'Roraima',
      abbr: 'RR'
    }, {
      id: 15,
      name: 'Pará',
      abbr: 'PA'
    }, {
      id: 16,
      name: 'Amapá',
      abbr: 'AP'
    }, {
      id: 17,
      name: 'Tocantins',
      abbr: 'TO'
    }, {
      id: 21,
      name: 'Maranhão',
      abbr: 'MA'
    }, {
      id: 22,
      name: 'Piauí',
      abbr: 'PI'
    }, {
      id: 23,
      name: 'Ceará',
      abbr: 'CE'
    }, {
      id: 24,
      name: 'Rio Grande do Norte',
      abbr: 'RN'
    }, {
      id: 25,
      name: 'Paraíba',
      abbr: 'PB'
    }, {
      id: 26,
      name: 'Pernambuco',
      abbr: 'PE'
    }, {
      id: 27,
      name: 'Alagoas',
      abbr: 'AL'
    }, {
      id: 28,
      name: 'Sergipe',
      abbr: 'SE'
    }, {
      id: 29,
      name: 'Bahia',
      abbr: 'BA'
    }, {
      id: 31,
      name: 'Minas Gerais',
      abbr: 'MG'
    }, {
      id: 32,
      name: 'Espírito Santo',
      abbr: 'ES'
    }, {
      id: 33,
      name: 'Rio de Janeiro',
      abbr: 'RJ'
    }, {
      id: 35,
      name: 'São Paulo',
      abbr: 'SP'
    }, {
      id: 41,
      name: 'Paraná',
      abbr: 'PR'
    }, {
      id: 42,
      name: 'Santa Catarina',
      abbr: 'SC'
    }, {
      id: 43,
      name: 'Rio Grande do Sul',
      abbr: 'RS'
    }, {
      id: 50,
      name: 'Mato Grosso do Sul',
      abbr: 'MS'
    }, {
      id: 51,
      name: 'Mato Grosso',
      abbr: 'MT'
    }, {
      id: 52,
      name: 'Goiás',
      abbr: 'GO'
    }, {
      id: 53,
      name: 'Distrito Federal',
      abbr: 'DF'
    }]);
  }

}
