import { IsBoolean, IsEmail, IsEnum, IsNumber, IsString } from 'class-validator';
import { PersonType } from '../enums/person-type.enum';

// tslint:disable: variable-name
export class RegisterForm {

  @IsEnum(PersonType)
  public type!: PersonType;

  @IsBoolean()
  public active!: boolean;

  @IsString()
  public name!: string;

  @IsEmail()
  public email!: string;

  @IsString()
  public password!: string;

  @IsString()
  public password_confirm!: string;

  @IsString()
  public cpf_cnpj!: string;

  @IsString()
  public cep!: string;

  @IsNumber()
  public state_id!: number;

  @IsNumber()
  public city_id!: number;

  @IsString()
  public address!: string;

  @IsNumber()
  public number!: number;

  @IsString()
  public neighborhood!: string;

  @IsString()
  public complement!: string;

  @IsBoolean()
  public agree_terms!: boolean;

  constructor(data?: Partial<RegisterForm>) {
    Object.assign(this, data);
  }

}
