import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, tap } from 'rxjs/operators';
import { IAddress } from 'src/app/core/interfaces/address.interface';
import { ICEP } from 'src/app/core/interfaces/cep.interface';
import { ICity } from 'src/app/core/interfaces/city.interface';
import { IState } from 'src/app/core/interfaces/state.interface';
import { CEPService } from 'src/app/core/services/cep.service';
import { CitiesService } from 'src/app/core/services/cities.service';
import { StatesService } from 'src/app/core/services/states.service';

@Component({
  selector: 'app-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss']
})
export class AddressFormComponent implements OnInit, OnChanges {

  @Input()
  public states: Array<IState> = [];

  @Input()
  public cities: Array<ICity> = [];

  @Input()
  public address!: IAddress;

  @ViewChild('number')
  public number!: ElementRef;

  public form = new FormGroup({
    cep: new FormControl('', [Validators.minLength(8), Validators.maxLength(9)]),
    state_id: new FormControl(null, []),
    city_id: new FormControl(null, []),
    neighborhood: new FormControl('', [Validators.maxLength(100)]),
    address: new FormControl('', [Validators.maxLength(100)]),
    number: new FormControl('', [Validators.min(0), Validators.max(99999)]),
    complement: new FormControl('', [Validators.maxLength(100)])
  });

  public loadingStates = false;
  public loadingCities = false;

  constructor(
    private cepService: CEPService,
    private statesService: StatesService,
    private citiesService: CitiesService
  ) { }

  private fetchCEP(cep: string): Observable<ICEP> {

    this.form.disable();

    return this.cepService
      .get(cep)
      .pipe(
        catchError((error) => {

          this.form.enable();

          return of(error);

        }),
        tap((response) => {

          if (response.state && response.city) {

            const state = this.states.find((item) => item.abbr === response.state);
            this.form.get('state_id')?.patchValue(state?.id);

            this.onStateChange(state as IState, (cities) => {

              const city = cities.find((item) => item.name === response.city);

              this.form.get('city_id')?.patchValue(city?.id);
              this.number.nativeElement.focus();

            });

          }

          if (response.neighborhood) {
            this.form.get('neighborhood')?.patchValue(response.neighborhood);
          }

          if (response.street) {
            this.form.get('address')?.patchValue(response.street);
          }

          this.number.nativeElement.focus();
          this.form.enable();

        })
      );

  }

  private fetchCitiesByState(id: number, callback?: (cities: Array<ICity>) => void): void {

    this.loadingCities = true;

    this.citiesService.getByState(id).subscribe((response) => {

      this.cities = response;
      this.loadingCities = false;

      if (callback) {
        callback(response);
      }

    });

  }

  public get hasAddress(): boolean {

    return [
      Boolean(this.form?.get('cep')?.value !== '' && this.form?.get('cep')?.valid),
      Boolean(this.form?.get('state_id')?.value !== '' && this.form?.get('state_id')?.value !== null),
      Boolean(this.form?.get('city_id')?.value !== '' && this.form?.get('city_id')?.value !== null),
      Boolean(this.form?.get('neighborhood')?.value !== ''),
      Boolean(this.form?.get('address')?.value !== ''),
      Boolean(this.form?.get('number')?.value !== ''),
    ].some((rule) => rule === true);

  }

  public onCEPBlur(): void {

    const cep = this.form.get('cep')?.value;

    if (cep.length === 8) {
      this.fetchCEP(cep).subscribe();
    }

  }

  public onStateChange(state: IState, callback?: (cities: Array<ICity>) => void): void {

    this.form.get('city_id')?.reset();

    this.loadingCities = true;

    this.citiesService.getByState(state.id).subscribe((cities) => {

      this.cities = cities;
      this.loadingCities = false;

      this.form.get('city_id')?.enable();

      if (callback) {
        callback(cities);
      }

    });

  }

  public ngOnChanges(changes: SimpleChanges): void {

    if (!changes?.address?.currentValue) {
      return;
    }

    if (changes?.address?.currentValue?.estado && !this.cities.length) {
      this.fetchCitiesByState(changes?.address?.currentValue?.estado.id);
    }

    if (changes?.address?.currentValue?.cidade) {

      this.loadingCities = false;
      this.form.get('city_id')?.enable();

    }

    this.form.patchValue(changes?.address?.currentValue);

  }

  public ngOnInit(): void {

    this.loadingStates = true;

    this.form.get('city_id')?.disable();

    if (this.form.get('city_id')?.value) {
      this.form.get('city_id')?.enable();
    }

    if (!this.states?.length) {

      this.statesService.getAll().subscribe((states) => {

        this.states = states;
        this.loadingStates = false;

      });

    }

  }

}
