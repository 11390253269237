import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RollbarErrorHandler, RollbarService } from '@core/errors/rollbar-error-handler';
import { rollbarFactory } from '@core/errors/rollbar-factory';
import { NgSelectModule } from '@ng-select/ng-select';
import { PasswordStrengthMeterModule } from 'angular-password-strength-meter';
import { ValidateEqualModule } from 'ng-validate-equal';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ptBrLocale } from 'ngx-bootstrap/locale';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { NgxMaskModule } from 'ngx-mask';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AddressFormComponent } from './components/address-form/address-form.component';
import { InputFeedbackComponent } from './components/input-feedback/input-feedback.component';
import { RegisterFormComponent } from './components/register-form/register-form.component';

defineLocale('pt-br', ptBrLocale);

@NgModule({
  declarations: [
    AppComponent,
    AddressFormComponent,
    InputFeedbackComponent,
    RegisterFormComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgSelectModule,
    FormsModule,
    ValidateEqualModule,
    PasswordStrengthMeterModule,
    BsDatepickerModule.forRoot(),
    PopoverModule.forRoot(),
    NgxMaskModule.forRoot(),
    ToastrModule.forRoot({
      preventDuplicates: true,
      countDuplicates: true,
      autoDismiss: true,
      resetTimeoutOnDuplicate: true
    }),
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    { provide: ErrorHandler, useClass: RollbarErrorHandler },
    { provide: RollbarService, useFactory: rollbarFactory }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
