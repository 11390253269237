import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { InputError } from 'src/app/core/enums/input-error.enum';
import { InputFeedbackService } from 'src/app/core/services/input-feedback.service';

@Component({
  selector: 'app-input-feedback',
  templateUrl: './input-feedback.component.html',
  styleUrls: ['./input-feedback.component.scss']
})
export class InputFeedbackComponent {

  @Input()
  public control!: AbstractControl;

  constructor(
    private inputFeedbackService: InputFeedbackService
  ) { }

  public getError(error: string = InputError.default): string {
    return this.inputFeedbackService.getMessage(this.control, error);
  }

}
