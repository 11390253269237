import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { RegisterForm } from '../models/register-form.model';

@Injectable({ providedIn: 'root' })
export class RegisterService {

  constructor(
    private httpClient: HttpClient
  ) { }

  public create(payload: RegisterForm): Observable<RegisterForm> {
    return this.httpClient.post<RegisterForm>(`${environment.api}register`, payload);
  }

}
